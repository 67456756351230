import { useMemo } from 'react'
import { Box, Button, Tooltip } from '@mui/material'
import { IconInfoCircle, IconPencil } from '@tabler/icons-react'
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { useApiPagination, useToggle } from '~/hooks'
import { Input, PageLoader, TableApi } from '~/components'
import { usePartner } from '~/contexts/partner'
import { useTheme } from '@emotion/react'
import { DealershipCell, UpdateBenefitsModal } from './components'
import { usePaginateDealershipConditionsQuery } from '~/graphql/types'

export const BenefitsPercentageTable: React.FC = () => {
  const theme = useTheme()
  const { myPartner } = usePartner()
  const { isTrue: updateBenefitsModalIsOpen, setIsTrue: setUpdateBenefitsModalIsOpen } = useToggle(true)
  const paginationData = useApiPagination({ pageSize: 1000000, currentPage: 0 })
  const { data, loading: dealershipIsLoading } = usePaginateDealershipConditionsQuery()

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'UF',
        accessor: 'uf',
      },
      {
        Header: 'Distribuidora',
        accessor: 'dealershipName'
      },
      {
        Header: 'Benefício mínimo',
        accessor: 'customerDiscountMin',
        Cell: ({ value }) => <DealershipCell pfValue={value as number} pjValue={value as number} /> 
      },
      {
        Header: 'Benefício máximo',
        accessor: 'customerDiscountMax',
        Cell: ({ value }) => <DealershipCell pfValue={value as number} pjValue={value as number} /> 
      },
      {
        Header: 'Benefício sugerido',
        accessor: 'customerDiscountSuggested',
        Cell: ({ value }) => <DealershipCell pfValue={value as number} pjValue={value as number} /> 
      },
      {
        Header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Benefício padrão
            <Tooltip title='Benefício base oferecido na indicação manual. Se esse campo não for preenchido, o benefício sugerido vai ser adotado.'>
              <IconInfoCircle size={24} color={theme.palette.info.dark} />
            </Tooltip>
          </Box>
        ),
        Cell: ({ row: { original } }: any) => {
          return (
            <Box sx={{ display: 'flex', gap: '.6rem' }}>
              <Input
                disabled={true}
                name={original.dealershipName}
                mask='percentage' 
              />
              <Input
                disabled={true}
                name={original.dealershipName}
                mask='percentage' 
              />
              <Button sx={{ padding: '0' }}>
                <IconPencil size={32} />
              </Button>
            </Box>
          )
        },
        accessor: 'customerDiscountOffered'
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.paginateDealershipConditions.data || [], [myPartner])

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      manualPagination: true,
      defaultColumn: {
        Filter: <></>,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  if(dealershipIsLoading) return <PageLoader />

  return (
    <>
      <TableApi
        columns={columns}
        tableLayout='fixed'
        tableMethods={tableMethods}
        paginationData={paginationData}
        options={{ lastColumnAlignRight: true }}
      />

      <UpdateBenefitsModal isVisible={updateBenefitsModalIsOpen} onClose={() => setUpdateBenefitsModalIsOpen(false)} />
    </>
  )
}
