import { useTheme } from '@emotion/react'
import { Box, Button, Divider } from '@mui/material'
import { IconCircleFilled, IconQuestionMark } from '@tabler/icons-react'
import { FormProvider, useForm } from 'react-hook-form'
import { Dialog, Input } from '~/components'

type UpdateBenefitsModalProps = {
  isVisible: boolean
  onClose: Function
}

export const UpdateBenefitsModal: React.FC<UpdateBenefitsModalProps> = ({ isVisible, onClose }) => {
  const theme = useTheme()
  const formMethods = useForm()

  return (
    <Dialog 
      titleIcon={
        <Box sx={{ position: 'relative' }}>
          <IconCircleFilled style={{ color: theme.palette.primary.main }} size={36} />
          <IconQuestionMark color='white' style={{ position: 'absolute', top: '7px', left: '7px' }} size={22} />
        </Box>
      } 
      title={`Editar benefício padrão - RGE`} 
      isVisible={isVisible} 
      onClose={() => onClose()}
    >
      <Divider />
      <FormProvider {...formMethods}>

        <form>
          <Box sx={{ display: 'flex', gap: '.8rem', padding: '2rem 0' }}>
            <Input mask='percentage' label='Benefício padrão PF' name='a' />
            <Input mask='percentage' label='Benefício padrão PJ' name='b' />
          </Box>

          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '.8rem', paddingTop: '1.2rem' }}>
            <Button color='secondary'>Cancelar</Button>
            <Button disabled>Salvar novo benefício</Button>
          </Box>
        </form>
      </FormProvider>
    </Dialog>
  )
}
