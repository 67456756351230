import { useTheme } from '@emotion/react'
import { Box, Button, CircularProgress, Divider, Drawer, IconButton, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material'
import { IconBusinessplan, IconCalendar, IconCheck, IconDownload, IconFilter, IconReceipt2, IconSearch, IconX } from '@tabler/icons-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { FormProvider, useForm } from 'react-hook-form'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { toast } from 'react-toastify'
import { Card, CheckboxMultiple, ComissionStatus, ContentTitle, Input, PerformanceCard, PerformanceCardProps, TableApi } from '~/components'
import { useComissions } from '~/contexts'
import { ECommissionStatus, useGetCommissionsCsvLazyQuery } from '~/graphql/types'
import { useDebounce, useToggle } from '~/hooks'
import { formatDate, formatMoney, transformMoney, translateComissionsStatus } from '~/utils'
import { comissionStatusOptions } from '~/utils/options'

type FilterTableData = {
  nameCustomerOrSalesman: string | null
  status: ECommissionStatus[]
  begin: '' | null,
  end: '' | null,
}

export const Commissions: React.FC = () => {
  const { comissions, comissionsListIsLoading, metricsComissions, paginationData } = useComissions()
  const theme = useTheme()
  const { isTrue: isVisible, toggle: toggleModal } = useToggle(false)
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const formMethods = useForm()

  const ref = useRef<any>()

  const [getAllComissionsCsv, { data: allComissionsCsv, loading: allComissionsIsLoading }] = useGetCommissionsCsvLazyQuery({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted() {
      ref?.current?.link?.click()
    }
  })

  // eslint-disable-next-line no-unused-vars
  const onFetchCSV = useCallback(() => {
    getAllComissionsCsv({
      variables: {
        params: {
          pageSize: 999999999,
          filter: {
            ...paginationData.filtersTable?.begin?.length > 0 ? { begin: new Date(paginationData.filtersTable?.begin) } : {},
            ...paginationData.filtersTable?.end?.length > 0 ? { end: new Date(`${paginationData.filtersTable?.end}Z23:59:59`) } : {}
          },
          sort: {
            field: 'createdAt',
            order: -1
          }
        }
      }
    })
  }, [paginationData])

  const [filterTable, setFilterTable] = useState<FilterTableData>({
    nameCustomerOrSalesman: null,
    status: [],
    begin: null,
    end: null,
  })

  const debouncedFilterTable = useDebounce(filterTable, 1000)

  const onApplyFilters = useCallback((formData: FilterTableData) => {
    setFilterTable(formData)
    toggleModal()
  }, [toggleModal, filterTable])

  const onRemoveFilters = useCallback(() => {
    formMethods.reset({
      begin: null,
      end: null,
      status: [],
      nameCustomerOrSalesman: null
    })
    setFilterTable({ nameCustomerOrSalesman: '', status: [], begin: null, end: null })
    toggleModal()
  }, [toggleModal])

  useEffect(() => {
    paginationData.setFiltersTable(filterTable)
    paginationData.setMetadata((prev) => ({ ...prev, currentPage: 0 }))
  }, [debouncedFilterTable])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'indicationRef.salesman.name',
      },
      {
        Header: 'Data de vencimento',
        accessor: 'dueDate',
        Cell: ({ value }) => <>{formatDate(value)}</>
      },
      {
        Header: 'Valor',
        accessor: 'valueCents',
        Cell: ({ value }) => <>{formatMoney(transformMoney(value, 'toReal'))}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <ComissionStatus status={value} />
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => comissions || [], [comissions])

  const defaultColumnHiddens = isLowerMd ? [].concat(isLowerSm ? [] : []) : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>,
    },
  },
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
    //disable checkboxColumn
    // (hooks) => renderTableCheckbox(hooks)
  )

  const cards: PerformanceCardProps[] = [
    {
      title: 'Receita esperada',
      description: 'Somatório das comissões esperadas para o período',
      value: formatMoney(transformMoney(metricsComissions?.receivableRevenueCents || 0, 'toReal')),
      icon: <IconReceipt2 color='#9E78BC' size={24} />
    },
    {
      title: 'Receita a receber',
      description: 'Somatório das comissões efetivas para o período',
      value: formatMoney(transformMoney(metricsComissions?.expectedRevenueCents || 0, 'toReal')),
      icon: <IconBusinessplan color='#9E78BC' size={24} />
    },
  ]

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          title='Comissões'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Consulte as comissões resultantes das indicações de assinatura Desperta'
          breadcrumbLinks={{ currentLink: 'Lista de comissões', links: [{ href: '/app', label: 'Comissão' }] }}
        />
        <Divider />

        <Box sx={{ padding: '1.6rem 0', display: 'flex', justifyContent: 'space-between', flexFlow: isLowerMd ? 'column' : 'row', gap: '1rem' }}>

          <TextField
            value={filterTable.nameCustomerOrSalesman}
            onChange={e => setFilterTable({ ...filterTable, nameCustomerOrSalesman: e.target.value })}
            sx={{ width: '100%', maxWidth: '420px' }}
            InputProps={{
              endAdornment: <InputAdornment position='end'>
                <IconSearch />
              </InputAdornment>,
            }}
            name='searchName'
            placeholder='Busque por palavra-chave'
          />

          <Box sx={{ display: 'flex', gap: '1rem', }}>
            <Button
              onClick={onFetchCSV}
              color='secondary'
              disabled={allComissionsIsLoading}
              startIcon={allComissionsIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconDownload size={24} />}
            >
              {allComissionsIsLoading ? 'exportando' : 'Exportar'}
            </Button>
            <CSVLink
              style={{ visibility: `hidden` }}
              filename='desperta-energia-comissões.csv'
              data={allComissionsCsv ? allComissionsCsv?.getCommissions.data.map(item => ({
                createdAt: formatDate(item.createdAt),
                dueDate: formatDate(item.dueDate),
                paymentDate: item.status === ECommissionStatus.paid ? formatDate(item.updatedAt) : null,
                status: translateComissionsStatus(item.status),
                'nome do vendedor': item.indicationRef.salesman.name,
                'ID do vendedor': item.indicationRef.salesman.id,
                'comissão base': `${item?.indicationRef?.condition?.baseCommissionPercentage}%`,
                'comissão adicional': `${item?.indicationRef?.condition?.additionalComissionPercentage}%`,
                'comissão final': `${item?.indicationRef?.condition?.commissionAmountPercentage}%`,
                'valor da comissão': formatMoney(transformMoney(item.valueCents, 'toReal')),
                estado: item?.indicationRef?.condition?.uf,
                concessionária: item?.indicationRef?.condition?.dealershipName,
              })) : []
              }
              ref={ref}
            >
            </CSVLink>
            <Button
              onClick={() => toggleModal()}
              startIcon={<IconFilter />}
              color='secondary'
            >
              Filtros
            </Button>
            <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={isVisible} onClose={toggleModal}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
                <IconButton type='button' onClick={toggleModal}>
                  <IconX />
                </IconButton>
              </Box>

              <FormProvider {...formMethods}>
                <form onSubmit={formMethods.handleSubmit((formData) => onApplyFilters(formData as FilterTableData))}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, paddingTop: '2rem' }}>
                      <Typography variant='h5' fontWeight={500}>Período de indicação</Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Input placeholder='Data inicial' fullWidth name='begin' type='date' />
                        <Typography>Até</Typography>
                        <Input fullWidth name='end' type='date' />
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='h5' fontWeight={500}>Status</Typography>
                      <CheckboxMultiple name='status' options={comissionStatusOptions.filter(item => item.value === ECommissionStatus.notPaid || item.value === ECommissionStatus.paid)} />
                    </Box>

                    <Divider />

                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
                      <Button onClick={onRemoveFilters} type='button' color='secondary'>Limpar</Button>
                      <Button type='submit' color='primary' startIcon={<IconCheck size={24} />}>Aplicar</Button>
                    </Box>
                  </Box>
                </form>
              </FormProvider>
            </Drawer>
          </Box>
        </Box>

        <Divider />

        <Box sx={{ display: 'flex', alignItems: isLowerMd ? 'flex-start' : 'center', gap: '.6rem', justifyContent: 'space-between', padding: '1.6rem 0', flexFlow: isLowerMd ? 'column' : 'row' }}>
          <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500, color: theme.palette.grey[800] }}>Performance</Typography>
            <Typography sx={{ color: theme.palette.grey[400] }}>Todas as comissões</Typography>
          </Box>
          {filterTable.begin && filterTable.end && (
            <Box sx={{ display: 'flex', alignItems: isLowerSm ? 'flex-start' : 'center', gap: '.6rem', flexFlow: isLowerSm ? 'column' : 'row' }}>
              <Typography sx={{ color: theme.palette.grey[400] }}>Resultados de</Typography>
              <Typography sx={{ color: theme.palette.grey[800], fontWeight: 500 }}>{formatDate(`${new Date(filterTable.begin)}`)} a {formatDate(`${new Date(filterTable.end)}`)}</Typography>
              {!isLowerSm && <IconCalendar color={theme.palette.primary.main} />}
            </Box>
          )}

        </Box>

        <Box sx={{ display: 'flex', gap: '1rem', flexFlow: isLowerMd ? 'column' : 'row' }}>
          {cards.map((card) => {
            return <PerformanceCard key={card.title} {...card} />
          })}
        </Box>

        <Divider sx={{ marginTop: '1.6rem' }} />

        <TableApi
          tableLayout='auto'
          isLoading={comissionsListIsLoading}
          paginationData={paginationData}
          tableMethods={tableMethods}
          columns={columns}
        />

      </Card>
    </Box>
  )
}
