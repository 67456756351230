import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { DealershipListData, DealershipsPartnerContextData } from './types'
import { EBrazilStates, usePaginateDealershipConditionsQuery } from '~/graphql/types'
import { toast } from 'react-toastify'

const DealershipsPartner = createContext({} as DealershipsPartnerContextData)

export const DealershipsPartnerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [dealerships, setDealerships] = useState<DealershipListData>([])

  const { data, loading: dealershipsIsLoading } = usePaginateDealershipConditionsQuery({

    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if(data) {
      setDealerships(data.paginateDealershipConditions.data as DealershipListData)
    }
  }, [data])

  console.log('dealer',dealerships, data?.paginateDealershipConditions )
 
  return (
    <DealershipsPartner.Provider value={{
      dealerships,
      setDealerships,
      dealershipsIsLoading
    }}>
      {children}
    </DealershipsPartner.Provider>
  )
}

export const useDealershipsPartner = () => useContext(DealershipsPartner)
